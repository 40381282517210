<script lang="ts" setup>
  import { useSwiper } from 'swiper/vue';
  import type { ICollections } from '~/types/movies';
  import VButton from '~/components/ui/VButton.vue';
  import { useCollections } from '~/composables/useCollections';

  interface IProps {
    collection: ICollections;
  }
  const swiper = useSwiper();
  const props = defineProps<IProps>();

  const { image, count, name, text, route } = useCollections(props.collection);
</script>

<template>
  <div class="collection-card" @click="navigateTo(route)">
    <v-lazy class="collection-card__image">
      <v-image :src="image" transition="fade">
        <template #loading></template>
        <template #error></template>
      </v-image>
    </v-lazy>
    <div class="collection-card__controls">
      <div class="collection-card__title">
        <span class="collection-card__count">{{ count }}</span>
      </div>
      <div class="collection-card__navigation">
        <v-button
          appearance="outline"
          size="normal"
          media="normal"
          icon="arrow-left-btn"
          color="gradient"
          title="Стрелка влево"
          rounded
          @click.stop="swiper.slidePrev()"
        />
        <v-button
          appearance="outline"
          size="normal"
          media="normal"
          icon="arrow-right-btn"
          color="gradient"
          title="Стрелка вправо"
          rounded
          @click.stop="swiper.slideNext()"
        />
      </div>
    </div>
    <div class="collection-card__description">
      <h2 class="collection-card__header">{{ name }}</h2>
      <p class="collection-card__text" v-html="text"></p>
      <v-button class="collection-card__button" media="normal" :to="route">Смотреть</v-button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .collection-card {
    --initial-font-size: 44px;
    --final-font-size: 34px;
    --min-width: 1100px;
    --max-width: 1440px;

    border-radius: 32px;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.02) 100%);
    overflow: hidden;
    display: grid;
    gap: 40px;
    grid-template-columns: 1fr auto 1fr;
    height: 578px;
    cursor: pointer;
    &__image {
      max-width: 400px;
      min-height: 578px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &__controls {
      display: flex;
      flex-direction: column;
      padding: 32px 0;
    }
    &__count {
      color: $text-color-main;
      font-size: 98px;
      font-style: normal;
      font-weight: 700;
      line-height: 118px;
    }
    &__title {
      display: grid;
    }
    &__navigation {
      display: flex;
      gap: 16px;
      margin-top: auto;
    }
    &__description {
      padding: 32px 32px 32px 0;
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
    &__header {
      color: $text-color-main;
      font-size: 44px;
      font-style: normal;
      font-weight: 700;
      line-height: 52px;
    }
    &__text {
      color: $text-color-main;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      display: -webkit-box;
      -webkit-line-clamp: 8;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    &__button {
      margin-top: auto;
    }
    @media (max-width: $tablet-large) {
      height: auto;
      &__text {
        -webkit-line-clamp: 6;
      }
    }
    @media (max-width: $tablet) {
      grid-template-columns: 1fr;
      grid-template-rows: 250px auto 332px;
      gap: 16px;
      &__image {
        min-height: 180px;
        max-width: 100%;
      }
      &__controls {
        padding: 0 16px;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
      &__navigation {
        margin: 0;
      }
      &__description {
        padding: 0 16px 16px;
      }
      &__header {
        font-size: 30px;
        line-height: 32px;
      }
      &__text {
        font-size: 16px;
        line-height: 20px;
      }
    }
    @media (max-width: $retina) {
      grid-template-columns: 1fr;
      grid-template-rows: 180px auto 332px;
      gap: 16px;
      &__image {
        min-height: 180px;
        max-width: 100%;
      }
      &__controls {
        padding: 0 16px;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
      &__count {
        font-size: 44px;
        line-height: 52px;
      }
      &__navigation {
        margin: 0;
      }
      &__description {
        padding: 0 16px 16px;
      }
      &__header {
        font-size: 24px;
        line-height: 32px;
      }
      &__text {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
</style>
